export * from './network';
export * from './numbers';
export * from './points';
export * from './timeAndDates';
export * from './tokens';
export * from './apy';

export const PERMISSION_LEVELS = {
  PRIVATE: 0,
  FUNCTION_LEVEL: 1,
  POOL_LEVEL: 2,
  PUBLIC: 3,
};

export const POLLING_INTERVAL = 15000; // 15 seconds

// Pendle
const PENDLE_CONTRACT_URL = '0x373dc7be84fadebc2e879c98289fc662c6985946';
const PENDLE_URL_BASE = 'https://app.pendle.finance';
// export const PENDLE_LINK_MARKET = `${PENDLE_URL_BASE}/trade/markets/${PENDLE_CONTRACT_URL}`;
// export const PENDLE_LINK_LP = `${PENDLE_URL_BASE}/pools/markets/${PENDLE_CONTRACT_URL}`;
export const PENDLE_LINK_POINTS = `${PENDLE_URL_BASE}/trade/points/${PENDLE_CONTRACT_URL}`;

// Feat flags
// ------------------------------

// - Template
// export const isFooEnabled = process.env.REACT_APP_FOO_ENABLED
//   ? JSON.parse(process.env.REACT_APP_FOO_ENABLED)
//   : false;

export const isClaimEnabled = process.env.REACT_APP_CLAIM_ENABLED
  ? JSON.parse(process.env.REACT_APP_CLAIM_ENABLED)
  : false;

export const isHomeV2Enabled = process.env.REACT_APP_HOMEV2_ENABLED
  ? JSON.parse(process.env.REACT_APP_HOMEV2_ENABLED)
  : false;

export const isRecommitEnabled = process.env.REACT_APP_RECOMMIT_ENABLED
  ? JSON.parse(process.env.REACT_APP_RECOMMIT_ENABLED)
  : false;
