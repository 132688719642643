import { Theme } from '@mui/material';
import { CSSProperties } from 'react';

interface SizeVariants {
  xLarge?: CSSProperties;
  large?: CSSProperties;
  medium: CSSProperties;
  small: CSSProperties;
  xSmall: CSSProperties;
  '2xSmall'?: CSSProperties;
}

type CSSAndSize = React.CSSProperties & SizeVariants;

declare module '@mui/material/styles' {
  interface TypographyVariants {
    label: CSSAndSize;
    paragraph: CSSAndSize;
    subheading: CSSAndSize;
    h1: React.CSSProperties;
    h2: React.CSSProperties;
    h3: React.CSSProperties;
    h4: React.CSSProperties;
    h5: React.CSSProperties;
    h6: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    label?: CSSAndSize;
    paragraph?: CSSAndSize;
    subheading?: CSSAndSize;
    h1?: React.CSSProperties;
    h2?: React.CSSProperties;
    h3?: React.CSSProperties;
    h4?: React.CSSProperties;
    h5?: React.CSSProperties;
    h6?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1: true;
    h2: true;
    h3: true;
    h4: true;
    h5: true;
    h6: true;
    label: true;
    paragraph: true;
    subheading: true;
    subtitle1: false;
    subtitle2: false;
    body1: false;
    body2: false;
    caption: false;
    overline: false;
    button: false;
  }
}

export const Inter = ['Inter', 'sans-serif'].join(',');
export const Agrandir = ['Agrandir Text Bold', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'].join(',');

const headingCommon = {
  fontWeight: 700,
  fontFamily: Agrandir,
  letterSpacing: '-0.015em',
};

export const generateTypography = (theme: Theme) => ({
  fontFamily: Inter,
  h0: {
    ...headingCommon,
    fontSize: '68px',
    lineHeight: '68px',
    [theme.breakpoints.down('md')]: {
      fontSize: '52px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '36px',
      lineHeight: '46.8px',
    },
  },
  h1: {
    ...headingCommon,
    fontSize: '56px',
    lineHeight: '64px',
    [theme.breakpoints.down('md')]: {
      fontSize: '48px',
      lineHeight: '56px',
      letterSpacing: '-0.48px',
    },
  },
  h2: {
    ...headingCommon,
    fontSize: '48px',
    lineHeight: '56px',
    [theme.breakpoints.down('md')]: {
      fontSize: '40px',
      lineHeight: '56px',
      letterSpacing: '-0.4px',
    },
  },
  h3: {
    ...headingCommon,
    fontSize: '40px',
    lineHeight: '48px',
    [theme.breakpoints.down('md')]: {
      fontSize: '32px',
      lineHeight: '48px',
      letterSpacing: '-0.32px',
    },
  },
  h4: {
    ...headingCommon,
    fontSize: '32px',
    lineHeight: '40px',
    [theme.breakpoints.down('md')]: {
      fontSize: '24px',
      lineHeight: '32px',
      letterSpacing: '0',
    },
  },
  h5: {
    ...headingCommon,
    fontSize: '24px',
    lineHeight: '32px',
    [theme.breakpoints.down('md')]: {
      fontSize: '20px',
      lineHeight: '28px',
      letterSpacing: '0',
    },
  },
  h6: {
    ...headingCommon,
    fontSize: '20px',
    lineHeight: '28px',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
      lineHeight: '20px',
      letterSpacing: '0',
    },
  },
  h7: {
    ...headingCommon,
    fontSize: '16px',
    lineHeight: '28px',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0',
    },
  },
  label: {
    fontFamily: Inter,
    fontWeight: 500,

    xLarge: desktopSizes.xLarge,
    large: desktopSizes.large,
    medium: desktopSizes.medium,
    small: desktopSizes.small,
    xSmall: {
      ...desktopSizes.xSmall,
      [theme.breakpoints.down('md')]: {
        fontSize: '12px',
        lineHeight: '16px',
      },
    },
    '2xSmall': {
      [theme.breakpoints.down('md')]: {
        fontSize: '10px',
        lineHeight: '16px',
        letterSpacing: '0px',
      },
    },
  },
  subheading: {
    fontFamily: Inter,
    fontWeight: 500,
    textTransform: 'uppercase' as CSSProperties['textTransform'],

    medium: {
      ...desktopSizes.medium,
      letterSpacing: '0.96px',
    },
    small: {
      ...desktopSizes.small,
      letterSpacing: '0.84px',
    },
    xSmall: {
      ...desktopSizes.xSmall,
      letterSpacing: '0.48px',
    },
    '2xSmall': desktopSizes['2xSmall'],
  },

  paragraph: {
    fontFamily: Inter,
    fontWeight: 400,

    xLarge: {
      ...desktopSizes.xLarge,
      [theme.breakpoints.down('md')]: {
        fontSize: '22px',
        lineHeight: '24px',
        letterSpacing: '-0.33px',
      },
    },
    large: {
      ...desktopSizes.large,
      [theme.breakpoints.down('md')]: {
        fontSize: '16px',
        lineHeight: '18px',
        letterSpacing: '-0.24px',
      },
    },
    medium: {
      ...desktopSizes.medium,
      [theme.breakpoints.down('md')]: {
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '-0.154px',
      },
    },
    small: {
      ...desktopSizes.small,
      [theme.breakpoints.down('md')]: {
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '-0.072px',
      },
    },
    xSmall: {
      ...desktopSizes.xSmall,
      [theme.breakpoints.down('md')]: {
        fontSize: '10px',
        lineHeight: '12px',
        letterSpacing: '0px',
      },
    },
  },
});

const desktopSizes: SizeVariants = {
  xLarge: {
    fontSize: '24px',
    lineHeight: '32px',
    letterSpacing: '-0.36px',
  },
  large: {
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '-0.27px',
  },
  medium: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.176px',
  },
  small: {
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '-0.084px',
  },
  xSmall: {
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0px',
  },
  '2xSmall': {
    fontSize: '11px',
    lineHeight: '12px',
    letterSpacing: '0.22px',
  },
};
